<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refBranchListTable"
        class="position-relative"
        :items="fetchRoles"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: UserRoleName -->
        <template #cell(RoleName)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.role_name }}</span>
          </div>
        </template>

        <!-- Column: UserRoleSystemName -->
        <template #cell(RoleSlug)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.role_slug }}</span>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRoles"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination,
} from 'bootstrap-vue'
import store from '@/store'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import useRolesList from './useRolesList'
import rolesStoreModule from '../rolesStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    vSelect,
  },
  mounted() {
    this.$root.$on('refreshTable', text => {
    console.log(text) // here you need to use the arrow function
    this.$refs.refBranchListTable.refresh()
  })
  },
  setup() {
    const BRANCH_APP_STORE_MODULE_NAME = 'app-branch'

    // Register module
    if (!store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.registerModule(BRANCH_APP_STORE_MODULE_NAME, rolesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalRoles,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBranchListTable,
      refetchData,

      // UI
      resolveBranchRoleVariant,
      resolveBranchRoleIcon,
      resolveBranchStatusVariant,

    } = useRolesList()

    return {

      // Sidebar
      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalRoles,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBranchListTable,
      refetchData,
      // UI
      resolveBranchRoleVariant,
      resolveBranchRoleIcon,
      resolveBranchStatusVariant,
      statusOptions,

    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
